import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
// import axios from 'axios';
import Header from '../components/Header';
import '../styles/Contact.css';
// import { Buffer } from 'buffer'; // Import Buffer

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formDataToSubmit = new FormData(event.target);

    formDataToSubmit.append("access_key", "6c1bb813-00af-4c49-b2f9-17b50a3adab2");

    const object = Object.fromEntries(formDataToSubmit);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      });

      const result = await res.json();
      if (result.success) {
        Swal.fire({
          title: "Success!",
          text: "Your message has been received. Someone from our team will respond to you shortly!",
          icon: "success"
        });
      } else {
        console.error("Error", result);
      }
    } catch (error) {
      console.error("Submission error", error);
    }
  };

  return (
    <div className="contact">
      <div>
    <Helmet>
      <title>RF Digital Solutions - Home</title>
      <meta name="description" content="RF Digital Solutions provides tailored digital solutions to clients. We deliver innovative and customized solutions to meet unique business needs." />
      <meta name="keywords" content="digital solutions, technology, business success, tailored solutions, innovative technology" />
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "RF Digital Solutions",
          "url": "https://rfdigisolutions.com",
          "logo": "../styles/images/new-logo.png",
          "description": "RF Digital Solutions provides tailored digital solutions to clients. We deliver innovative and customized solutions to meet unique business needs."
        }
        `}
      </script>
    </Helmet>
    </div>
      <Header />
      <div className="contact-container">
        <h1 className="contact-h">
          If you have any questions or need a quotation, fill out the form below
          and someone from our team will reach out to you shortly!
        </h1>
        <h1 className="contact-h">Contact Us!</h1>

        <form onSubmit={onSubmit}>
          <div className="contact-row">
            <div className="contact-field">
              <label htmlFor="name" className="contact-label">Name</label>
              <input
                className="top"
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="contact-field">
              <label htmlFor="email" className="contact-label">Email</label>
              <input
                className="top"
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="contact-field">
            <label htmlFor="subject" className="contact-label">Subject</label>
            <input
              className="bottom"
              type="text"
              name="subject"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="contact-field">
            <label htmlFor="message" className="contact-label">Message</label>
            <textarea
              className="bottom message"
              name="message"
              id="message"
              placeholder="Write your message below..."
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <button className="btn" type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
