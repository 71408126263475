import React from 'react';
import '../styles/Reachout.css';
import Image1 from '../styles/images/hero.png';
import Image2 from '../styles/images/hero.png';


const Reachout = () => {
    return(
      <section className="reach-out-section">
      <div className="reach-out-container">
        <div className="image-collage">
          <img src={Image1} alt="reachout" className="collage-image image1" />
          <img src={Image2} alt="reachout image2" className="collage-image image2" />
          {/* <img src={Image3} alt="Collage Image 3" className="collage-image image3" /> */}
        </div>
        <div className="reach-out-text">
          <h2 className="reach-out-title">Get Started Today!</h2>
          <p className="reach-out-description">
            Connect with like-minded professionals who are as passionate about technology and innovation as you are. Let’s collaborate and create something extraordinary together!
          </p>
          <button className="reach-out-button">
            <a href="mailto:rfdigisolutions@gmail.com">Let’s Chat</a>
          </button>
        </div>
      </div>
    </section>
    )
}

export default Reachout;