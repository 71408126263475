import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Typewriter from 'typewriter-effect';
import '../styles/Services.css';
import Header from '../components/Header';
import Reachout from '../components/Reachout.js';
// import Slider from '../components/Slider';
import { MdExpandMore } from 'react-icons/md';

const Services = () => {
  const [isInView, setIsInView] = useState({
    about: false,
    reviews: false,
  });

  const aboutRef = useRef(null);
  const reviewsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.id;
            setIsInView((prevState) => ({
              ...prevState,
              [id]: true,
            }));
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );
  
    // Copy the current ref values to local variables
    const aboutElement = aboutRef.current;
    const reviewsElement = reviewsRef.current;
  
    if (aboutElement) observer.observe(aboutElement);
    if (reviewsElement) observer.observe(reviewsElement);
  
    return () => {
      if (aboutElement) observer.unobserve(aboutElement);
      if (reviewsElement) observer.unobserve(reviewsElement);
    };
  }, []); // The empty dependency array ensures this effect runs only once
  

  return (
    <div className="services-container">
      <div>
    <Helmet>
      <title>RF Digital Solutions - Home</title>
      <meta name="description" content="RF Digital Solutions provides tailored digital solutions to clients. We deliver innovative and customized solutions to meet unique business needs." />
      <meta name="keywords" content="digital solutions, technology, business success, tailored solutions, innovative technology" />
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "RF Digital Solutions",
          "url": "https://rfdigisolutions.com",
          "logo": "../styles/images/new-logo.png",
          "description": "RF Digital Solutions provides tailored digital solutions to clients. We deliver innovative and customized solutions to meet unique business needs."
        }
        `}
      </script>
    </Helmet>
    </div>
      <Header />
      <div className="services-content services-page">
        <div ref={aboutRef} id="about">
          <h1>
            {isInView.about && (
              <Typewriter
                options={{
                  strings: [
                    `<strong>Our Services...</strong>`
                  ],
                  autoStart: true,
                  loop: false,
                  delay: 75,
                  cursor: "", // Hides the cursor after typing is done
                  deleteSpeed: Infinity, // Ensure no deletion
                }}
              />
            )}
          </h1>
        </div>

        <div className="services-cards">
          <div className="service-card">
            <h2>Web Design</h2>
            <div className="faq-dropdowns">
              <details>
                <summary>
                  What is included?
                  <MdExpandMore className="dropdown-icon" />
                </summary>
                <p>
                  Our Web Design service includes a custom website layout, responsive design for mobile and tablet devices, user-friendly navigation, and integration with essential tools and analytics. We also provide SEO-friendly design to improve your site's visibility on search engines.
                </p>
              </details>
              <details>
                <summary>
                  Project Duration
                  <MdExpandMore className="dropdown-icon" />
                </summary>
                <p>
                  The timeline for a Web Design project can vary depending on the complexity and scope. Typically, a standard project takes between 4 to 8 weeks from initial consultation to final delivery. This includes design, development, testing, and revisions.
                </p>
              </details>
              <details>
                <summary>
                  Project Cost
                  <MdExpandMore className="dropdown-icon" />
                </summary>
                <p>
                  Pricing for Web Design services depends on the project's complexity, features required, and design specifications. We offer customized quotes based on your specific needs. Contact us for a detailed proposal and pricing information.
                </p>
              </details>
            </div>
          </div>

          <div className="service-card">
            <h2>Custom App Development</h2>
            <div className="faq-dropdowns">
              <details>
                <summary>
                  What is included?
                  <MdExpandMore className="dropdown-icon" />
                </summary>
                <p>
                  Whether you’re aiming to streamline operations, boost customer engagement, or launch a new product, we’ve got you covered. Our Custom App Development service provides end-to-end solutions, from initial design to final implementation. We start by crafting intuitive user interfaces (UI) and seamless user experiences (UX) that align with your business needs. Our development process encompasses robust backend development and integration with third-party services to ensure your application is both functional and scalable. With a focus on performance optimization, we deliver tailored applications designed to drive your business forward.
                </p>
              </details>
              <details>
                <summary>
                  Development Process
                  <MdExpandMore className="dropdown-icon" />
                </summary>
                <p>
                  Our development process involves several stages: initial consultation, requirements gathering, UI/UX design, development, testing, and deployment. We maintain open communication with you throughout the process to ensure the final product meets your expectations.
                </p>
              </details>
              <details>
                <summary>
                  App Security
                  <MdExpandMore className="dropdown-icon" />
                </summary>
                <p>
                  We implement various security measures to protect your application, including encryption, secure authentication methods, regular security audits, and compliance with industry standards. Our goal is to safeguard your data and ensure the app’s integrity.
                </p>
              </details>
            </div>
          </div>

          <div className="service-card">
            <h2>Strategic SEO Consulting</h2>
            <div className="faq-dropdowns">
              <details>
                <summary>
                  What is included?
                  <MdExpandMore className="dropdown-icon" />
                </summary>
                <p>
                  SEO Consulting includes a thorough analysis of your current SEO strategy, keyword research, on-page and off-page optimization, and competitive analysis. We provide actionable recommendations to enhance your search engine visibility and drive targeted traffic to your site.
                </p>
              </details>
              <details>
                <summary>
                  Tracking Process
                  <MdExpandMore className="dropdown-icon" />
                </summary>
                <p>
                  We measure SEO success using various metrics, including organic search rankings, website traffic, conversion rates, and ROI. We use analytics tools to track these metrics and provide regular reports on your SEO performance.
                </p>
              </details>
              <details>
                <summary>
                  Consulting Cost
                  <MdExpandMore className="dropdown-icon" />
                </summary>
                <p>
                  The cost of SEO Consulting varies depending on the scope of the services provided and the complexity of your SEO needs. We offer customized pricing based on your specific requirements. Contact us to get a detailed quote and service plan.
                </p>
              </details>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="slider-section">
        <div ref={reviewsRef} id="reviews">
          <h1>
            {isInView.reviews && (
              <Typewriter
                options={{
                  strings: [
                    `<strong>Hear what people are saying...</strong>`
                  ],
                  autoStart: true,
                  loop: false,
                  delay: 75,
                  cursor: "", // Hides the cursor after typing is done
                  deleteSpeed: Infinity, // Ensure no deletion
                }}
              />
            )}
          </h1>
        </div>
        <Slider />
      </div> */}

      {/* Contact Section */}
      <Reachout />
    </div>
  );
};

export default Services;
