import React, { useEffect, useState, useRef } from 'react';
import '../styles/Analytics.css';
import '../styles/GlobalStyle.css';

const Analytics = () => {
  const [satisfaction, setSatisfaction] = useState(0);
  const [conversion, setConversion] = useState(0);
  const [engagement, setEngagement] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const analyticsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = analyticsRef.current; // Copy ref to variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      const satisfactionInterval = setInterval(() => {
        setSatisfaction((prev) => (prev < 100 ? prev + 1 : prev));
      }, 30);

      const conversionInterval = setInterval(() => {
        setConversion((prev) => (prev < 98 ? prev + 1 : prev));
      }, 20);

      const engagementInterval = setInterval(() => {
        setEngagement((prev) => (prev < 85 ? prev + 1 : prev));
      }, 50);

      return () => {
        clearInterval(satisfactionInterval);
        clearInterval(conversionInterval);
        clearInterval(engagementInterval);
      };
    }
  }, [isInView]);

  return (
    <section className="analytics" ref={analyticsRef}>
      <h2>Our Company's Impact!</h2>
      <div className="analytics-cards">
        <div className="card">
          <h3>Customer Satisfaction</h3>
          <p className="number">{satisfaction}%</p>
        </div>
        <div className="card">
          <h3>Conversion Rate</h3>
          <p className="number">{conversion}%</p>
        </div>
        <div className="card">
          <h3>Engagement Metrics</h3>
          <p className="number">{engagement}%</p>
        </div>
      </div>
    </section>
  );
};

export default Analytics;