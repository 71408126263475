import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../components/Header.js';
import Analytics from '../components/Analytics';
import Typewriter from 'typewriter-effect';
import line from '../styles/images/line.png';
import Reachout from '../components/Reachout.js';
import '../styles/Contact.css';
import '../styles/GlobalStyle.css';
import '../styles/Home.css';

const Home = () => {
  const [isAboutInView, setIsAboutInView] = useState(false);
  const [isInfoInView, setIsInfoInView] = useState(false);
  const [isServicesInView, setIsServicesInView] = useState(false);
  const aboutRef = useRef(null);
  const infoRef = useRef(null);
  const servicesRef = useRef(null);

  useEffect(() => {
    const aboutNode = aboutRef.current;
    const infoNode = infoRef.current;
    const servicesNode = servicesRef.current;

    const aboutObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsAboutInView(true);
            aboutObserver.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const infoObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInfoInView(true);
            infoObserver.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const servicesObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsServicesInView(true);
            servicesObserver.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (aboutNode) aboutObserver.observe(aboutNode);
    if (infoNode) infoObserver.observe(infoNode);
    if (servicesNode) servicesObserver.observe(servicesNode);

    return () => {
      if (aboutNode) aboutObserver.unobserve(aboutNode);
      if (infoNode) infoObserver.unobserve(infoNode);
      if (servicesNode) servicesObserver.unobserve(servicesNode);
    };
  }, []);

  return (
    <div className="home-container">
      <div>
        <Helmet>
          <title>RF Digital Solutions - Home</title>
          <meta
            name="description"
            content="RF Digital Solutions provides tailored digital solutions to clients. We deliver innovative and customized solutions to meet unique business needs."
          />
          <meta
            name="keywords"
            content="digital solutions, technology, business success, tailored solutions, innovative technology"
          />
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "RF Digital Solutions",
              "url": "https://rfdigisolutions.com",
              "logo": "../styles/images/new-logo.png",
              "description": "RF Digital Solutions provides tailored digital solutions to clients. We deliver innovative and customized solutions to meet unique business needs."
            }
            `}
          </script>
        </Helmet>
      </div>
      <Header />

      <section className="hero-container">
        <h1 className="hero-text">Transforming ideas into digital excellence!</h1>
      </section>
      <img className="scroll-line" src={line} alt="logo" />

      {/* About Section */}
      <section className="about" ref={aboutRef}>
        <h2>
          {isAboutInView && (
            <Typewriter
            options={{
              strings: [
                `<strong class="typewriter-bold">Let rfds</strong> = <span class="typewriter-green">{'{brandname:"RF Digital Solutions"};'}</span>`
              ],
              autoStart: true,
              loop: false,
              delay: 75,
              cursor: "", // Hides the cursor after typing is done
              deleteSpeed: Infinity, // Ensure no deletion
            }}
          />
          )}
        </h2>
        <div className={`about-info ${isInfoInView ? 'slide-up' : ''}`} ref={infoRef}>
          <p>
            Inspired by a passion for technology and a commitment to helping businesses 
            succeed, this brand aims to deliver innovative and customized solutions that
            meet the unique needs of each client. Our mission is to build a community of 
            collaboration and growth within the tech industry.
          </p>
          <Link to="/about" className="btn">ABOUT US</Link>
        </div>
      </section>
      <img className="scroll-line" src={line} alt="logo" />

      {/* Services Section */}
      <section className={`services ${isServicesInView ? 'slide-up' : ''}`} ref={servicesRef}>
        <h2>WHAT WE DO</h2>
        <div className="service-info">
          <p>
            At RF Digital Solutions, we specialize in delivering a 
            comprehensive suite of digital services tailored to meet 
            your unique business needs. Our expertise spans across a variety
            of key areas, ensuring that your business remains competitive and
            thrives in the digital landscape.
          </p>
          <Link to="/services" className="btn">SERVICES</Link>
        </div>
      </section>
      <img className="scroll-line" src={line} alt="logo" />

      {/* Analytics Section */}
      <section className="analytics">
        <Analytics />
      </section>
      <img className="scroll-line" src={line} alt="logo" />

      {/* Contact Section*/}
      <Reachout />
    </div>
  );
};

export default Home;
