import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Typewriter from 'typewriter-effect';
import Header from '../components/Header';
import Reachout from '../components/Reachout.js';
import '../styles/About.css';
import image1 from '../styles/images/avatar1.jpg';
import image2 from '../styles/images/avatar2.jpg';
import image3 from '../styles/images/avatar3.jpg';

const content = [
  {
    title: 'About',
    text: 'RF Digital Solutions was created to provide tailored digital solutions to clients. Inspired by a passion for technology and a commitment to helping businesses succeed, this brand aims to deliver innovative and customized solutions that meet the unique needs of each client.',
    image: image1,
  },
  {
    title: 'Founder',
    text: `Hello! I'm Rawan, the founder of RF Digital Solutions. My journey in the tech industry began with a passion for harnessing technology to create meaningful and impactful digital solutions. Over the years, I've dedicated myself to developing innovative strategies and tools that help businesses thrive in the digital landscape. At RF Digital Solutions, we focus on delivering cutting-edge web and app development services, tailored to meet the unique needs of each client. My commitment to excellence and my drive to stay ahead of industry trends enable me to craft solutions that not only meet but exceed expectations. I'm excited to connect and explore how we can work together to achieve your business goals!`,
    image: image2,
  },
  {
    title: 'Goals for the Future',
    text: `At RF Digital Solutions, our primary goal is to drive continuous innovation and deliver exceptional services that empower businesses to excel in a rapidly evolving digital world. We are committed to staying at the forefront of technology, leveraging the latest advancements to create tailored solutions that address our clients' unique challenges and opportunities. Our mission is to expand our reach and foster enduring partnerships with our clients, guiding them through their digital transformation journey.`,
    image: image3,
  },
];

const About = () => {
  const [selectedContent, setSelectedContent] = useState(content[0]);
  const typewriterRef = useRef(null);

  useEffect(() => {
    const typewriterElement = typewriterRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (typewriterElement) {
              typewriterElement.classList.add('animate');
            }
            observer.unobserve(entry.target); // Stop observing once the animation starts
          }
        });
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (typewriterElement) {
      observer.observe(typewriterElement);
    }

    return () => {
      if (typewriterElement) {
        observer.unobserve(typewriterElement);
      }
    };
  }, []);

  return (
    <div className="about">
      <div>
    <Helmet>
      <title>RF Digital Solutions - Home</title>
      <meta name="description" content="RF Digital Solutions provides tailored digital solutions to clients. We deliver innovative and customized solutions to meet unique business needs." />
      <meta name="keywords" content="digital solutions, technology, business success, tailored solutions, innovative technology" />
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "RF Digital Solutions",
          "url": "https://rfdigisolutions.com",
          "logo": "../styles/images/new-logo.png",
          "description": "RF Digital Solutions provides tailored digital solutions to clients. We deliver innovative and customized solutions to meet unique business needs."
        }
        `}
      </script>
    </Helmet>
    </div>
      <Header />
      <div className="about-intro">
        <img src={image1} alt="Intro" />
        <div className="about-intro-text">
          <h1 className="handwritten-text">RF Digital Solutions</h1>
          <div className="moving-bar"></div>
          <p>
            RF Digital Solutions was created to provide tailored digital solutions to clients. Inspired by a passion for technology and a commitment to helping businesses succeed, this brand aims to deliver innovative and customized solutions that meet the unique needs of each client.
          </p>
        </div>
      </div>
      <h1 className="about-h1" ref={typewriterRef}>
        <Typewriter
          options={{
            strings: ['Who We Are...'],
            autoStart: true,
            loop: false,
            delay: 75,
            cursor: "", // Hides the cursor after typing is done
            deleteSpeed: Infinity, // Ensure no deletion
          }}
        />
      </h1>
      <div className="about-container">
        <div className="about-main-content">
          <div className="about-image-container">
            <img
              src={selectedContent.image}
              alt={selectedContent.title}
              className="about-main-image"
            />
          </div>
          <div className="about-text-container">
            <div className="about-details">
              <h1>{selectedContent.title}</h1>
              <p>{selectedContent.text}</p>
            </div>
            <div className="about-options">
              {content.map((item, index) => (
                <div
                  key={index}
                  className="about-option"
                  onMouseEnter={() => setSelectedContent(item)}
                >
                  <h2>{item.title}</h2>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Reachout />
    </div>
  );
};

export default About;
