import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import '../styles/GlobalStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from '../styles/images/new-white-logo.png'; // Update this path if necessary

const Footer = () => (
  <footer className="footer">
    <div className="footer-content">
      <div className="footer-section about-us">
        <img src={logo} alt="Company Logo" className="footer-logo" />
      </div>
      <div className="footer-section">
        <h3>Quick Links</h3>
        <ul>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/services">Services</Link>
        </ul>
      </div>
      <div className="footer-section contact-us">
        <h3>Contact Us</h3>
        <div className="social-links">
        <a href="mailto:info@rfdigitalsolutions.com" className="social-icon">
          <FontAwesomeIcon icon={faEnvelope} />
          </a>
        <a href="https://www.linkedin.com/company/rf-digital-solutions-llc/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://www.instagram.com/rfdigisolutions/?igsh=djVscmE5aW9vMzJx&utm_source=qr" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <p>&copy; 2024 RF Digital Solutions, LLC. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
